import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';

// LOGO IMAGE 
import CarelSert from './Serts/carel_sert.jpg'

const CarelPageHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
            <div className="refeng-page-container" style={{display: 'grid', alignContent: 'center'}}>
                <div className="postcontent">
                    <Container fluid>
                        <Row>
                            <Col xl={8}>
                                <h4>Компания Carel</h4>
                                <br/>
                                <p>Компания CAREL является современным экспертом в сфере научно-технологических 
                                    разработок и всегда предупреждает рыночный спрос, предлагая энергоэффективные 
                                    системы управления, обеспечивающие высокие эксплуатационные характеристики оборудования.</p>

                                    <p>Непрерывное совершенствование методов работы позволяет компании CAREL всегда достигать 
                                    установленных стандартов в области качества и технологий, не достигая при этом предела 
                                    своих возможностей, за счет чего качество продукции постепенно возрастает и, как следствие, 
                                    выходит на новый уровень.</p>

                                    <p>Проведение тщательной научно-исследовательской работы, внедрение инновационных разработок 
                                    и технологий являются ключевыми факторами на пути к успеху: на протяжении более сорока лет 
                                    потребности клиентов остаются главным ориентиром при проведении проектных работ, в результате 
                                    чего рождаются предложения, уникальные по своим функциональным возможностям и конструктивным 
                                    особенностям.</p>
                                <p>
                                    <strong>Сайт компании:</strong>
                                    <a href="https://www.carel.com/" rel="noopener noreferrer nofollow" target = "_blank"  aria-label="Carel website">
                                    &nbsp;&nbsp;www.carel.com
                                    </a>
                                </p>
                            </Col>
                            <Col></Col>
                            <Col xl={2} style={{paddingTop: '7vh'}}>
                                <Zoom>
                                    <img
                                    alt="Сертификат о партнерстве компании Рефинжиниринг с Carel"
                                    src={CarelSert}
                                    width="100%"
                                    />
                                </Zoom>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default CarelPageHtml