import React from 'react';
import Seo from "../../components/seo"
import CarelPageHtml from '../../components/OtherPages/PartnersPages/carelpage';

function CarelPage() {
    return (
        <>
            <Seo 
                title="Партнер Рефинжиниринг Carel"
                description="Официальный партнер компании 'Рефинжиниринг'- Carel"
                keywords="Carel, автоматика, электроника, контроллеры, PLC, холодильная, партнер"
            />
            <CarelPageHtml />
        </>
    );
}

export default CarelPage;